$full_width: 65%;

.content {
  position: relative;
  height: 100%;
  background-color: red;
  display: flex;

  &__element {
    width: calc(100% - #{$full_width});
    transition: width ease 300ms;
    position: relative;
    overflow: hidden;

    &.full {
      width:  $full_width;
    }

    &__hit-detector,
    &__hitten-detector {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(1);
      transform-origin: center;
      cursor: pointer;
      border: 1px solid green;
      //background: rgba(yellow, 50%);
      z-index: 10;
    }

    &__hitten-detector {
      transform: scale(0);
    }
  }


  // pour le dictionnaire wow effect là


  &__children {
    transition: opacity 150ms 150ms ease;
    opacity: 1;
    height: 100%;
    position: relative;
    overflow-y: scroll;

    &.hide {
      opacity: 0;
    }
  }

  //&__overlay {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  background-color: var(--color-orange);
  //  z-index: 1;
  //  transform: translateX(-100%);
  //
  //  &.animate {
  //    animation: animate 1000ms ease;
  //  }
  //}
}

@keyframes animate {
  from { transform: translateX(-100%)}
  to { transform: translateX(100%)}
}
