@import 'fonts';
@import 'variables';
@import '~css.gg/icons/all.scss';

body {
  margin: 0;
  font-family: var(--font), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  height: var(--main-height);
}
