.alphabet {
  height: calc(var(--header-height) / 2);
  display: flex;
  justify-content: center;

  &__case {
    width: calc(100% / 25);
    text-align: center;

    & > input {
      display: none;
    }

    & > label {
      display: block;
      position: relative;
      height: 100%;
      cursor: pointer;
      line-height: calc(var(--header-height) / 2);
      color: var(--color-black);
      overflow: hidden;
      transition: linear color 150ms;
    }

    & > label:before {
      content: '';
      width: 100%;
      height: 100%;
      background: var(--color-orange);
      z-index: -1;
      top: 0;
      left: 0;
      position: absolute;
      transform: scale(0, 1);
      transform-origin: right center;
      transition: transform linear 200ms;
    }

    & > h1 > span {
      color: var(--color-orange);
    }

    & > input:checked + label {
      color: var(--color-white);
    }

    & > input:checked + label:before {
      transform: scale(1, 1);
      transform-origin: left center;
    }
  }
}
