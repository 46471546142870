.words {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: var(--color-black);
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;

    & > div:first-child {
      color: var(--color-white);
    }

    & > div:last-child {
      color: var(--color-orange);
    }
  }
}
