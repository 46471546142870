.subtheme {
  margin-top: 20px;
  display: flex;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 150ms 400ms ease,
  transform 150ms 400ms ease;

  &--open {
    opacity: 1;
    transform: translateX(0);
  }

  &__item {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    color: var(--color-black);
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 150ms ease,
    color 150ms ease;
    text-transform: uppercase;
  }

  &--selected {
    background-color: var(--color-black);
    color: var(--color-white);
    border-color: var(--color-black);
  }
}
