@use "sass:map";
@use "sass:meta";

$fonts: (
        name: 'Roboto',
        ttf: Regular,
        ttfItalic: Italic,
        weight: 400,
        rootName: 'font'
), (
        name: 'Roboto Thin',
        ttf: Thin,
        weight: 100,
        rootName: 'font-thin'
), (
        name: 'Roboto Light',
        ttf: Light,
        weight: 300,
        rootName: 'font-light'
), (
        name: 'Roboto Medium',
        ttf: Medium,
        weight: 500,
        rootName: 'font-medium'
), (
        name: 'Roboto Bold',
        ttf: Bold,
        weight: 700,
        rootName: 'font-bold'
), (
        name: 'Roboto Black',
        ttf: Black,
        weight: 900,
        rootName: 'font-black'
);


@each $f in $fonts {
  $name: './Roboto-';
  $shade: map.get($f, ttf);
  $shadeItalic: $shade + 'Italic';
  $ext: '.ttf';

  @font-face {
    font-family: #{map.get($f, name)};
    src: url($name + $shade  + $ext);
  }

  @if (meta.type-of(map.get($f, ttfItalic)) == string) {
    $shadeItalic: map.get($f, ttfItalic);
  }

  @font-face {
    font-family: #{map.get($f, name)} + ' Italic';
    src: url($name + $shadeItalic + $ext);
  }

  %font-#{map.get($f, weight)},
  %font-#{map.get($f, weight)}-i {
    font-weight: map.get($f, weight);
  }

  %font-#{map.get($f, weight)} {
    font-family: #{map.get($f, name)};
  }

  %font-#{map.get($f, weight)}-i {
    font-family: #{map.get($f, name)} + ' Italic';
  }
}

:root {
  @each $f in $fonts {
    --#{map.get($f, rootName)}: #{map.get($f, name)};
  }
}
