$title-size: 55px;
$transition-duration: 350ms;

.dropdown {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--open,
  &--close {
    transition: opacity $transition-duration linear,
    transform $transition-duration linear;
  }

  &--open {
    opacity: 1;
    transform: scale(1);
  }

  &--close {
    opacity: 0;
    transform: scale(0);
    height: 0 !important;
  }

  &__themes {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__current {
    border: 2px solid var(--color-black);
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    max-width: 200px;
  }

  &__item {
    font-size: 20px;
    text-transform: capitalize;
  }

  .swiper,
  .swiper-wrapper,
  .swiper-slide {
    position: relative;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: transform ease 300ms;
    cursor: pointer;

    &.swiper-slide-active {
      transform: scale(1.5);
    }
  }
}
