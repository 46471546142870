$title-size: 220px;

.header {
  height: var(--header-height);
  box-sizing: border-box;

  &__top {
    display: flex;
    height: calc(var(--header-height) / 2);
    border-bottom: 1px solid var(--color-grey-light);

    & > div:first-child {
      width: calc(100% - var(--content-width));
      position: relative;
      display: flex;
    }

    & > div:last-child {
      width: var(--content-width);
    }

    & h1 {
      width: $title-size;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-black);
      color: var(--color-grey);
      margin: 0;
      height: 100%;

      & > a {
        text-decoration: none;
        color: inherit;
      }

      & span {
        color: var(--color-orange);
      }
    }
  }

  &__input {
    width: calc(100% - #{$title-size});
    display: flex;
    align-items: center;
  }
}
