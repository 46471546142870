.type-select {
  margin-top: 20px;

  &__choices {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    color: var(--color-black);
    border-radius: 5px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    margin: 5px;
    transition: background-color 150ms ease,
    color 150ms ease;
    text-transform: uppercase;
  }

  &--selected {
    background-color: var(--color-black);
    color: var(--color-white);
    border-color: var(--color-black);
  }
}
