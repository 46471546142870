.input {
  width: 100%;
  height: 100%;
  position: relative;

  &__input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    padding-left: 40px;
    font-size: 16px;
    color: var(--color-black);
  }

  &__icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-black);
  }
}
