.dictionary {
  position: relative;

  &__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
  }
}
